// initial state
const state = () => ({
	options: [],
})


// getters
const getters = {
	getOptions(state) {
		return state.options
	},

	getOptionsDotacniRok: (state) => (year) => {
		try {
			return state.options['dotacni_roky'][year]
		} catch {
			return null
		}
	},

	getOptionsAktivniDotacniRok(state) {
		console.log('xx')
		const aktivni = Object.keys(state.options['dotacni_roky'])
			.map(rok => ({
				rok,
				...state.options['dotacni_roky'][rok]
			}))
			.filter(rok => rok.aktivni)
		console.log(aktivni)
		if (aktivni.length === 1) {
			return aktivni[0]
		}
		return null
	}
}


// actions
const actions = {

}


// mutations
const mutations = {
	SET_OPTIONS(state, options) {
		state.options = options
	},
}


// export
export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
}